// Body
$body-bg: #f8fafc;

// Typography
//$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-sans-serif: 'Lato', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$components-height: 35px;

//$blue:    #007bff !default;

$pink: #ED028C;
$blue: #00ADEF;
$yellow: #EEF80E;

$primary:       $blue !default;
