//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

// Material design
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// Fontawesome
@import '~@fortawesome/fontawesome-free/css/all.css';

@import 'variables';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";

// Cropperjs
@import '~cropperjs/dist/cropper.css';

@import 'general';
@import 'login';
@import 'commerces';
@import 'tutorial';
@import 'benefits';
