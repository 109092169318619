// Cambiar font
@import '~@angular/material/theming';

$custom-typography: mat-typography-config(
  $font-family: $font-family-sans-serif
);

@include mat-base-typography($custom-typography);
@include angular-material-typography($custom-typography);

html, body {
    min-height: 100%;
    font-family: $font-family-sans-serif;
    font-display: swap;
}

body {
    margin: 0;
    background: #F5F5F5;
}
.dev-badge {
    background-color: #eb3629;
    color: white;
    padding: 10px;
    width: 100%;
}
body.guest-offers {
    background: #edf3f5;
}
.app-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .dev-badge {
        margin-top: 56px;
        position: fixed;
        z-index: 2;
    }
    .app-sidenav-container {
        /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
           `<body>` to be our scrolling element for mobile layouts. */
        flex: 1 0 auto;
    }
    .app-toolbar {
        position: fixed;
        /* Make sure the toolbar will stay on top of the content as it scrolls past. */
        z-index: 2;
    }
}

a {
    color: $blue;
}

button:focus {
    outline-color: $pink;
}
.col-white {
    color: #fff;
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: $blue;
}

.mat-drawer-container {
    background-color: inherit;
}

.mat-toolbar.mat-primary {
    background-color: $blue;
}

.mat-toolbar.innerlogo {
    background-image: url(/assets/img/moneo-logo-fill-v1-blanco.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 20px;
}

.mat-card-header-text {
    margin: 0 !important;
}
.mat-raised-button {
    &.mat-primary, &.mat-blue, &.mat-yellow {
        border: 1px solid #000000;
        box-shadow: 3px 3px 0 0 rgba(0,0,0,0.80);
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
    }

    &.mat-primary {
        background-color: $pink;
    }

    &.mat-blue {
        background-color: $blue;
    }

    &.mat-yellow {
        background-color: $yellow;
    }
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: $blue;
}

.mat-dialog-actions {
    button {
        margin-bottom: 10px;
    }
}

.mat-card-header {
    .mat-card-title {
        margin-bottom: 8px !important;
    }
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $blue;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $blue;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $blue;
}

.phone-small {
    font-size: 0.7rem !important;
    margin-right: 5px;
    margin-top: 1px;
}

.mat-fab.mat-primary {
    background-color: $blue;
}

.nav-back {
    color: #211f20;
    font-size: 1.3rem;
    margin-left: 2px;
}

.commerce-card,
.register-content {
    box-shadow: none !important;
}

.balance-card {
    background-color: #211F20;
    margin: 0 !important;
    color: #fff;
    border-radius: 0 !important;

    h2 {
        color: #E3ED02;
    }

    .ckx {
        font-size: 12px;
        line-height: 16px !important;
    }
}

.disable-select {
   user-select: none;
   -webkit-user-select: none;
   -webkit-touch-callout: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
}

.space-pre-wrap {
    white-space: pre-wrap;
}

.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .5);
}
.vh-50 {
    height: 50vh !important;
}

.fs-12px {
    font-size: .75rem;
}

.fs-14px {
    font-size: .875rem;
}

.fs-15px {
    font-size: 0.938rem !important;
}

.fs-16px {
    font-size: 1rem !important;
}

.fs-17px {
    font-size: 1.063rem;
}

.fs-18px {
    font-size: 1.125rem;
}

.fs-20px {
    font-size: 1.25rem;
}

.line-height-normal {
    line-height: normal;
}

.circle-badge {
    width: 25px;
    height: 25px;
    background-color: #f00;
    color: #fff;
    border-radius: 50%;
}

#dialog-slider-tutorial {
    padding: 0;
    .carousel-inner, .carousel-item {
        height: 100%;
    }
}
